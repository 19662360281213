import React, { cloneElement } from "react"
import PropTypes from "prop-types"
import axios from "axios"
//import "./topbar.less"
// import Logo from "./xforce-logo-small.png"
import {parseSearch, serializeSearch} from "../../core/utils"
// import {PasswordAuthorization} from "swagger-client"

export default class Topbar extends React.Component {

  static propTypes = {
    layoutActions: PropTypes.object.isRequired,
    authActions: PropTypes.object.isRequired
  }

  constructor(props, context) {
    super(props, context)
    this.validate = this.validate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addBasicAuthorization= this.addBasicAuthorization.bind(this);

    this.state = {
      url: props.specSelectors.url(),
      selectedIndex: 0,
      api_key:'',
      password:''
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ url: nextProps.specSelectors.url() })
  }

  onUrlChange =(e)=> {
    let {target: {value}} = e
    this.setState({url: value})
  }

  flushAuthData() {
    const { persistAuthorization } = this.props.getConfigs()
    if (persistAuthorization)
    {
      return
    }
    this.props.authActions.restoreAuthorization({
      authorized: {}
    })
  }

  loadSpec = (url) => {
    this.flushAuthData()
    this.props.specActions.updateUrl(url)
    this.props.specActions.download(url)
  }

  onUrlSelect =(e)=> {
    let url = e.target.value || e.target.href
    this.loadSpec(url)
    this.setSelectedUrl(url)
    e.preventDefault()
  }

  downloadUrl = (e) => {
    this.loadSpec(this.state.url)
    e.preventDefault()
  }

  setSearch = (spec) => {
    let search = parseSearch()
    search["urls.primaryName"] = spec.name
    const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
    if(window && window.history && window.history.pushState) {
      window.history.replaceState(null, "", `${newUrl}?${serializeSearch(search)}`)
    }
  }

  setSelectedUrl = (selectedUrl) => {
    const configs = this.props.getConfigs()
    const urls = configs.urls || []

    if(urls && urls.length) {
      if(selectedUrl)
      {
        urls.forEach((spec, i) => {
          if(spec.url === selectedUrl)
            {
              this.setState({selectedIndex: i})
              this.setSearch(spec)
            }
        })
      }
    }
  }

  componentDidMount() {
    const configs = this.props.getConfigs()
    const urls = configs.urls || []

    if(urls && urls.length) {
      var targetIndex = this.state.selectedIndex
      let primaryName = configs["urls.primaryName"]
      if(primaryName)
      {
        urls.forEach((spec, i) => {
          if(spec.name === primaryName)
            {
              this.setState({selectedIndex: i})
              targetIndex = i
            }
        })
      }

      this.loadSpec(urls[targetIndex].url)
    }
  }

  onFilterChange =(e) => {
    let {target: {value}} = e
    this.props.layoutActions.updateFilter(value)
  }

  handleChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  validate(){

    var key = encodeURIComponent(this.state.api_key);
    var pass = encodeURIComponent(this.state.password);

    var hostname = "";
    console.log();
    if (!window.location.host.includes("xforce")) {
      hostname="localhost:3000";
      hostname += "/api/user/profile";
    } else {
      hostname="api.xforce.ibmcloud.com";
      hostname += "/user/profile";
    }
    // console.log("http://"+hostname);
    axios.get("https://"+hostname, {
      withCredentials: true,
      auth: {
        username: key,
        password: pass
    }
    })
    .then((response) => {
      console.log(response);
    }, (error) => {
      console.log(error);
    });

    this.addBasicAuthorization();
  }

  addBasicAuthorization() {
    console.log("inside auth block");
    var key = encodeURIComponent(this.state.api_key);
    var pass = encodeURIComponent(this.state.password);
    window.sessionStorage.setItem("key", key);
    window.sessionStorage.setItem("pass", pass);
    if (key && key.trim() != "" && pass && pass.trim() != "") {
        // var apiBasicAuth = window.SwaggerClient.PasswordAuthorization(key, pass);
        window.swaggerUi.api.clientAuthorizations.add("basicAuth", 
          new SwaggerClient.PasswordAuthorization(
            this.state.api_key,
            this.state.password));
        log("added key and pass " + JSON.stringify(apiBasicAuth));
     
    }
  }

  render() {
    let { getComponent, specSelectors, getConfigs } = this.props
    const Button = getComponent("Button")
    const Link = getComponent("Link")

    let isLoading = specSelectors.loadingStatus() === "loading"
    let isFailed = specSelectors.loadingStatus() === "failed"

    const classNames = ["download-url-input"]
    if (isFailed) classNames.push("failed")
    if (isLoading) classNames.push("loading")

    const { urls } = getConfigs()
    let control = []
    let formOnSubmit = null

    if(urls) {
      let rows = []
      urls.forEach((link, i) => {
        rows.push(<option key={i} value={link.url}>{link.name}</option>)
      })

      control.push(
        <label className="select-label" htmlFor="select"><span>Select a definition</span>
          <select id="select" disabled={isLoading} onChange={ this.onUrlSelect } value={urls[this.state.selectedIndex].url}>
            {rows}
          </select>
        </label>
      )
    }
    else {
      formOnSubmit = this.downloadUrl
      control.push(<input className={classNames.join(" ")} type="text" onChange={ this.onUrlChange } value={this.state.url} disabled={isLoading} />)
      control.push(<Button className="download-url-button" onClick={ this.downloadUrl }>Explore</Button>)
    }

    return (
      // <div className="topbar">
      //   <div className="wrapper">
      //     <div className="topbar-wrapper">
      //       {/* <Link>
      //         <img height="40" src={ Logo } alt="Swagger UI"/>
      //       </Link> */}
      //       {/* <form className="download-url-wrapper" onSubmit={formOnSubmit}>
      //         {control.map((el, i) => cloneElement(el, { key: i }))}
      //       </form> */}
            
      //       <div id="title">
      //         <img src={ Logo } id="img" alt="XFE"/>
      //         <a id="logo">
      //             IBM X-Force Exchange API
      //         </a>
      //       </div>
      //       <span>
      //         <div className="api_selector">
              
      //           <label for="input_apiUser" className="topbar-label">Key</label>
      //           <input name="api_key" value={this.state.api_key} onChange={this.handleChange} placeholder="api key" role="input" aria-label="Api key field" type="text" className="api_texbox"/>
                
      //           <label for="input_apiPass" className="topbar-label">Password</label>
      //           <input name="password" value={this.state.password} onChange={this.handleChange} placeholder="password" autocomplete="off" aria-label="Api password field"  type="password" className="api_texbox"/>
                
      //           <button className="btn-authenticate" onClick={this.validate}> Authenticate </button>
      //         </div>
      //       </span>
            
      //     </div>
      //   </div>
      // </div>
      <div></div>
    )
  }
}

Topbar.propTypes = {
  specSelectors: PropTypes.object.isRequired,
  specActions: PropTypes.object.isRequired,
  getComponent: PropTypes.func.isRequired,
  getConfigs: PropTypes.func.isRequired
}
